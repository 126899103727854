import { useEffect, useState } from "react";
import Navbar from '../../components/navbar';
import Categoria from "../../components/categoria";
import Banner from "../../components/banner";
import Estabelecimento from "../../components/estabelecimento";
import Footer from "../../components/footer";
import api from '../../services/api';
import LoadingSpinner from '../../components/loading-spinner'; // Assumindo que você tem um componente de spinner

function Home(){

    const [categorias, setCategorias] = useState([]);
    const [banners, setBanners] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [destaques, setDestaques] = useState([]);
    const [loading, setLoading] = useState(true); // Estado para controlar o loading
    
    useEffect(() => {
        setLoading(true); // Inicia o loading

        // Requisições da API
        Promise.all([
            api.get('v1/categorias?cod_cidade=' + localStorage.getItem('sessionCodCidade')),
            api.get('v1/banners?cod_cidade=' + localStorage.getItem('sessionCodCidade')),
            api.get('v1/destaques?cod_cidade=' + localStorage.getItem('sessionCodCidade'))
        ])
        .then(([categoriasRes, bannersRes, destaquesRes]) => {
            setCategorias(categoriasRes.data);
            setBanners(bannersRes.data);

            let gruposUnico = destaquesRes.data.map(grupo => grupo.descricao);
            gruposUnico = gruposUnico.filter((itemArray, i, arrayCompleto) => {
                return arrayCompleto.indexOf(itemArray) === i;
            });

            setGrupos(gruposUnico);
            setDestaques(destaquesRes.data);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false); // Finaliza o loading quando todas as requisições terminarem
        });
    }, []);

    if (loading) {
        return <LoadingSpinner />; // Mostra o spinner enquanto carrega
    }

    return (
        <div className="container-fluid mt-page">
            <Navbar />

            <div className="row justify-content-center text-center">
                {categorias.map(categoria => (
                    <Categoria 
                        key={categoria.id_categoria} 
                        url_imagem={categoria.foto}
                        descricao={categoria.categoria}
                        id_categoria={categoria.id_categoria} 
                    />
                ))}
            </div>

            <div className="row justify-content-center text-center mt-5 m-2">
                {banners.map(banner => (
                    <Banner 
                        key={banner.id_banner} 
                        url_imagem={banner.foto}
                        descricao={banner.descricao}
                        id_banner={banner.id_banner} 
                    />
                ))}
            </div>

            {grupos.map(grupo => (
                <div key={grupo} className="row mt-5 m-2">
                    <h4>{grupo}</h4>
                    {destaques.map(destaque => (
                        destaque.descricao === grupo ? (
                            <Estabelecimento 
                                key={destaque.id_estabelecimento}
                                url_imagem={destaque.url_logo}
                                nome={destaque.nome}
                                avaliacao={destaque.avaliacao}
                                categoria={destaque.categoria}
                                id_estabelecimento={destaque.id_estabelecimento} 
                            />
                        ) : null
                    ))}
                </div>
            ))}

            <Footer />
        </div>
    );
}

export default Home;
