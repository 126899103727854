import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute(props) {
    const logado = localStorage.getItem('sessionToken') ? true : false;
    const location = useLocation();  // Captura a URL atual (rota acessada)

    return logado 
        ? props.children  // Se estiver logado, renderiza o componente filho
        : <Navigate to="/login" state={{ from: location }} />;  // Redireciona para o login, passando a rota original
}

export default PrivateRoute;
