import { createContext, useEffect, useState } from 'react';
import api from '../services/api';

const CartContext = createContext({});

function CartProvider(props){
     
    const [cart, setCart] = useState([]);
    const [subtotalCart, setSubtotalCart] = useState(0);
    const [descontoCart, setDescontoCart] = useState(0);
    const [entregaCart, setEntregaCart] = useState(0);
    const [idCupomCart, setIdCupomCart] = useState(0);
    const [cupomCart, setCupomCart] = useState('');
    const [msgCart, setMsgCart] = useState('');
    const [totalCart, setTotalCart] = useState(0);
    const [idEstabelecimentoCart, setIdEstabelecimentoCart] = useState(0);
    const [horariosFuncionamento, setHorariosFuncionamento] = useState([]);
    const [aberto, setAberto] = useState(false);
 
    function SalvarCart(produtos){

        if (produtos.length > 0) {
            
            localStorage.setItem('sessionCart', JSON.stringify({
                cupom: cupomCart,
                id_cupom: idCupomCart,
                id_estabelecimento: idEstabelecimentoCart,
                entrega: entregaCart,
                itens: produtos
            }));
        } else {
            localStorage.removeItem('sessionCart');
        }
    }

    function AddItemCart(item){
        setCart([...cart, item]);
        SalvarCart([...cart, item]);
    }

    function RemoveItemCart(id){
        const novoCart = cart.filter((item, index, arr) => {
            return item.id_carrinho !== id;
        });
 
        setCart(novoCart);
        SalvarCart(novoCart);
    }

    function ValidarCupom(){
        setMsgCart('');
        SalvarCart(cart);

        api.get('v1/cupons/validacao', {
            params: {
                cod_cupom: cupomCart,
                valor: Math.trunc(subtotalCart * 100),
                id_estabelecimento: idEstabelecimentoCart
            }
        })
        .then(response => {
            
            if (response.data) {
                let porc_cupom = response.data[0].porc_cupom; // 5
                let vl_cupom = response.data[0].vl_cupom;  // 10.00
                   
                setIdCupomCart(response.data.id_cupom);
                setDescontoCart( Number(vl_cupom) + ( subtotalCart *  porc_cupom / 100));
                
                
            } else {
                setIdCupomCart(0);
                setDescontoCart(0);
                setMsgCart('Cupom inválido');
            }
        })
        .catch(err => {
            setIdCupomCart(0);
            setDescontoCart(0);
            setMsgCart('Cupom inválido');
            console.log(err);
        });
    }

    useEffect(() => {
        const dados = localStorage.getItem('sessionCart');
        
        if (dados){
            setCart(JSON.parse(dados).itens);
            setCupomCart(JSON.parse(dados).cupom);
            setIdEstabelecimentoCart(JSON.parse(dados).id_estabelecimento);
            setEntregaCart(JSON.parse(dados).entrega);
            setIdCupomCart(JSON.parse(dados).id_cupom);
        }

    }, []);

    useEffect(() => {
        if (cupomCart.length > 0) {
            ValidarCupom();
        }
    }, [subtotalCart]);

    useEffect(() => {
       setMsgCart('');
    }, [cupomCart]);
   
    useEffect(() => {
        let soma = cart.reduce((a, b) => Number(a) + (Number(b.vl_unit) * Number(b.qtd)), 0);
        setSubtotalCart(soma);       
    }, [cart]);

    useEffect(() => {    
        setTotalCart( Number(subtotalCart) -  Number(descontoCart) +  Number(entregaCart));        
    }, [subtotalCart, descontoCart, entregaCart]);

 //// Função para verificar se o estabelecimento está aberto--------------------------------------------------------------------------------------------------   
 
 useEffect(() => {
    if (horariosFuncionamento.length > 0) {
        setAberto(verificarAbertura(horariosFuncionamento));
    } else {
        setAberto(false); // Ou defina o valor padrão desejado
    }
  }, [horariosFuncionamento]); 

 function verificarAbertura() {
    const hoje = new Date();
    const diaSemana = hoje.getDay(); 
    const horaAtual = hoje.getHours() * 60 + hoje.getMinutes(); 

    const diaSemanaMap = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

    // Encontra o horário de funcionamento para o dia atual, comparando os dias de forma case insensitive
    const horarioHoje = horariosFuncionamento.find(horario => 
        horario.dia_semana.toLowerCase() === diaSemanaMap[diaSemana].toLowerCase()
    );

    if (horarioHoje) {
        const [horaAbertura, minAbertura] = horarioHoje.h_abertura.split(':').map(Number);
        const [horaFechamento, minFechamento] = horarioHoje.h_fechamento.split(':').map(Number);

        const aberturaEmMinutos = horaAbertura * 60 + minAbertura;
        const fechamentoEmMinutos = horaFechamento * 60 + minFechamento;

        // Verifica se a hora atual está no intervalo de abertura e fechamento
        if (aberturaEmMinutos < fechamentoEmMinutos) {
            // Caso normal, dentro do mesmo dia
            return horaAtual >= aberturaEmMinutos && horaAtual <= fechamentoEmMinutos;
        } else {
            // Caso em que o estabelecimento fecha no dia seguinte (horário de fechamento passa da meia-noite)
            return horaAtual >= aberturaEmMinutos || horaAtual <= fechamentoEmMinutos;
        }
    }

    // Caso não haja horário de funcionamento definido para o dia
    return false;
}

    return <CartContext.Provider value={{cart, setCart, subtotalCart, setSubtotalCart, descontoCart, setDescontoCart,
                                        entregaCart, setEntregaCart, idCupomCart, setIdCupomCart, totalCart, setTotalCart,
                                        idEstabelecimentoCart, setIdEstabelecimentoCart, AddItemCart, RemoveItemCart,
                                        ValidarCupom, cupomCart, msgCart, setCupomCart, setMsgCart,
                                        horariosFuncionamento, setHorariosFuncionamento, verificarAbertura, aberto, setAberto}}>
        {props.children}
    </CartContext.Provider>
}

export {CartContext, CartProvider};