import { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Pedido from "../../components/pedido";
import Footer from "../../components/footer"; // Caso tenha um footer
import api from '../../services/api';
import LoadingSpinner from '../../components/loading-spinner'; // Assumindo que você tenha um componente de spinner

function Pedidos() {

    const [pedidos, setPedidos] = useState([]);
    const [loading, setLoading] = useState(true); // Estado para controlar o loading


    function ListarPedidos () {
        setLoading(true); // Inicia o loading
        api.get('/v1/pedidos')
            .then(response => {
                setPedidos(response.data);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false); // Finaliza o loading após a requisição
            });

    }

    useEffect(() => {  
            ListarPedidos();    
            const interval = setInterval(() => {
                ListarPedidos();
            }, 60000);     
            return () => clearInterval(interval);     
    }, []);


    if (loading) {
        return <LoadingSpinner />; // Mostra o spinner enquanto carrega
    }

    return (
        <div className="container-fluid mt-page">
            <Navbar />

            <div className="row col-lg-8 offset-lg-2">
                <div className="col-12 mt-4">
                    <h2 className="mt-2">Meus Pedidos</h2>
                </div>

                <div className="row mt-5">
                    {pedidos.length > 0 ? (
                        pedidos.map(pedido => (
                            <Pedido 
                                key={pedido.id_pedido}
                                url_imagem={pedido.url_logo}
                                avaliacao={pedido.avaliacao}
                                qtd_item={pedido.qtd_item}
                                id_pedido={pedido.id_pedido}
                                vl_total={pedido.vl_total}
                                dt_pedido={pedido.dt_pedido}
                                id_estabelecimento={pedido.id_estabelecimento}
                                status={pedido.status}
                            />
                        ))
                    ) : (
                        <p className="text-center">Você não tem pedidos no momento.</p>
                    )}
                </div>
            </div>

            <Footer /> {/* Caso tenha um footer */}
        </div>
    );
}

export default Pedidos;
