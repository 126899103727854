import { useEffect, useState } from "react";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import api from "../../services/api";
import './cartao.css';

export function Cartao(props) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initMercadoPago = async () => {
      await loadMercadoPago();
      const mp = new window.MercadoPago("TEST-ef238eb2-ec9c-4549-bcdc-d513e5e9418c");

      const cardForm = mp.cardForm({
        amount: String(props.amount),
        iframe: true,
        form: {
          id: "form-checkout",
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número do cartão",
          },
          expirationDate: {
            id: "form-checkout__expirationDate",
            placeholder: "MM/YY",
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "Código de segurança",
          },
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Titular do cartão",
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emissor",
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Parcelas",
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Número do documento",
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
          },
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error) console.warn("Form Mounted handling error:", error);
            else console.log("Form mounted");
          },
          onSubmit: async (event) => {
            event.preventDefault();

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = cardForm.getCardFormData();

            try {
              const response = await api.post("/v1/process_payment", {
                token,
                issuer_id,
                payment_method_id,
                transaction_amount: Number(amount),
                installments: Number(installments),
                description: props.description,
                payer: {
                  email,
                  identification: {
                    type: identificationType,
                    number: identificationNumber
                  }                
                },
                external_reference: props.id_venda,
                notification_url: `https://api01de.jcode.dev.br/v1/webhook`,
                id_pagador: props.id_pagador,
                id_estabelecimento: props.id_estabelecimento
              });

              if (response.data.status === "approved") {
                props.onPaymentStatusChange("approved");
              }
              
            } catch (error) {
              console.error("Erro ao processar o pagamento:", error);
            }
          },
          onFetching: (resource) => {
            console.log("Fetching resource:", resource);
            setIsLoading(true);

            // Retorna uma função que será chamada quando o carregamento terminar
            return () => setIsLoading(false);
          },
        },
      });
    };

    initMercadoPago();
  }, [props.amount]);

  return (
    <>
      <form id="form-checkout">
        <div id="form-checkout__cardNumber" className="container"></div>
        <div id="form-checkout__expirationDate" className="container"></div>
        <div id="form-checkout__securityCode" className="container"></div>
        <input type="text" id="form-checkout__cardholderName" placeholder="Titular do cartão" />
        <select id="form-checkout__issuer"></select>
        <select id="form-checkout__installments" disabled></select>
        <select id="form-checkout__identificationType"></select>
        <input type="text" id="form-checkout__identificationNumber" placeholder="Número do documento" />
        <input type="email" id="form-checkout__cardholderEmail" placeholder="E-mail" />

        <button type="submit" id="form-checkout__submit">Pagar</button>
        <progress value={isLoading ? null : "0"} className="progress-bar">Carregando...</progress>
      </form>
    </>
  );
}

export default Cartao;
