import React, { useEffect, useState } from 'react';
import './style.css'; // Importar o CSS

    const LoadingDots = (props) => {
        return (
                <div className="loading-dots">
                    <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                </div>  
        );      
      };


export default LoadingDots;
