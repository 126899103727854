import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Rotas from './rotas';
import './styles/global.css';
import { CartProvider } from './contexts/cart';


function App() {
  useEffect(() => {
    // Define o idioma da tag <html> para pt-BR
    document.documentElement.lang = 'pt-BR';
  }, []);

  return (
    <CartProvider>
      <Rotas />
    </CartProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
